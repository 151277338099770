import localforage from "localforage";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import arrowBack from "../../assets/svg/back.svg";
import bookmarkOff from "../../assets/svg/bookmark_off@1x.svg";
import bookmarkon from "../../assets/svg/bookmark_on@1x.svg";
import BookViewRelated from "../../components/BookViewRelated/BookViewRelated";
import Button from "../../components/Button/Button";
import DialogBox from "../../components/DialogBox/DialogBox";
import Input from "../../components/Input/Input";
import Rating from "../../components/Rating/Rating";
import Textarea from "../../components/Textarea/Textarea";
import {
  AddMemberFavouriteByMemberIdUsingPUTRequest,
  BorrowBookUsingPOSTRequest,
  BorrowControllerApi,
  BorrowDto,
  DeleteMemberFavouriteByMemberIdUsingDELETERequest,
  GetMemberFavouritesByMemberIdUsingGETRequest,
  GetOnlineReadSametimeUsingGETRequest,
  GetProductByProductIdUsingGETRequest,
  InsertProductCommentUsingPOSTRequest,
  ListAllMyBorrowUsingPOSTRequest,
  ListAllMyReserveBookUsingPOSTRequest,
  ListReserveByBookUsingGETRequest,
  MemberControllerApi,
  MyBorrowStatus,
  MyBorrowStatusStatusEnum,
  ProductBorrowMyStatusUsingPOSTRequest,
  ProductCommentControllerApi,
  ProductControllerApi,
  ProductDto,
  ProductPointFromJSONTyped,
  ReserveBookUsingPOSTRequest,
  ReserveBorrow,
  SuccessResponseModel,
  SystemSetting,
  SystemSettingControllerApi,
  TestIpAddressUsingGETRequest,
  WhiteListIpControllerApi,
} from "../../fetcher";
import {
  ReportRecordControllerApi,
  SaveReportRecordUsingPOSTBookTypeEnum,
  SaveReportRecordUsingPOSTReportTypeEnum,
} from "../../fetcher";
import { IProductAllListModel } from "../../model";
import { handleLogin } from "../../redux/actions/login/login";
import { myBookMarkList } from "../../redux/actions/myBookMark/myBookMark";
import { myBorrowBookList } from "../../redux/actions/myBorrowBook/myBorrowBookList";
import { myReserveBookList } from "../../redux/actions/myReserverBook/myReserveBook";
import { handleReaderMode } from "../../redux/actions/reader/reader";
import { handleUserInfo } from "../../redux/actions/userInfo";
import * as PATH from "../../utils/Contants";
import { genApiConfig } from "../../utils/fetch-caller";
import {
  dateToString,
  getFileExtension,
  validateEmail,
} from "../../utils/GlobalFunction";
import BookUtil from "../../utils/reader/fileUtils/bookUtil";
import { addEpub } from "../../utils/reader/fileUtils/epubUtil";
import { getMd5WithBrowser } from "../../utils/reader/fileUtils/md5Util";
import RecentBooks from "../../utils/reader/readUtils/recordRecent";
import StorageUtil from "../../utils/reader/serviceUtils/storageUtil";
import useSocket from "../../utils/useSocket";
import style from "./BookDetail.module.css";

//let socket: any = null;

function BookDetail(props: any) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [toggleInfo, setToggleInfo] = useState("info");
  const [displayData, setDisplayData] = useState<any>({});
  const [productBorrowStatus, setProductBorrowStatus] = useState<any>();
  const [openBorrowDialog, setOpenBorrowDialog] = useState(false);
  const [openReserveDialog, setOpenReserveDialog] = useState(false);
  const [openBookmarkDialog, setOpenBookmarkDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(0);
  const [bookMarked, setBookMarked] = useState(
    props.bookMarkList.bookMarkList.filter(
      (it: any) => it.productId == params.id!
    ).length > 0
  );
  const [commentDisplay, setCommentDisplay] = useState([]);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingProd, setLoadingProd] = useState(false);
  const [loadingOnlineRead, setLoadingOnlineRead] = useState(false);

  const rootElement = document.getElementById("root");
  const [currentPage, setCurrerntPage] = useState(1);
  const [pagenum, setPagenum] = useState(0);
  const [pageStatusStart, setPageStatusStart] = useState(false);
  const [pageStatusEnd, setPageStatusEnd] = useState(false);
  const [hiddenOnlineRead, setHiddenOnlineRead] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [bookingEmail, setBookingEmail] = useState("");
  const [needEmail, setNeedEmail] = useState(true);
  const { socketMsg, setDetailPage, setBookPage } = useSocket();

  const [reserveList, setReserveList] = useState<Array<ReserveBorrow>>([]);

  const [borrowBookMaxDialog, setBorrowBookMaxDialog] = useState(false);
  const [reserveBookMaxDialog, setReserveBookMaxDialog] = useState(false);

  const [confirmOpenBookDialog, setConfirmOpenBookDialog] = useState(false);

  const OpenCC = require("opencc-js");
  const converter = OpenCC.Converter({ from: "hk", to: "cn" });

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState("");

  const [schoolList, setSchoolList] = useState([]);
  const [schoolObj, setSchoolObj] = useState<any>();
  const [isWhiteListIp, setIsWhiteListIp] = useState(false);
  const [onlineReadSametime, setOnlineReadSametime] = useState(1);

  useEffect(() => {
    fetch("https://image.nblib.com/js/custom_js/school.json")
      .then((response) => response.json())
      .then((data) => {
        setSchoolList(data);
      })
      .catch((err) => {
        console.log("catch", err);
      });
  }, []);

  useEffect(() => {
    setSchoolObj(
      schoolList.find(
        (obj: any) => `${obj.id}library` === process.env.REACT_APP_WEB_NAME
      )
    );
  }, [schoolList]);

  //const [onlineReadBook, setOnlineReadBook] = useState(false);
  //const [socketMsg, setSocketMsg] = useState("");

  useEffect(() => {
    if (openEmailDialog) {
      setNeedEmail(true);
    }
  }, [openEmailDialog]);

  const fetchIpAddress = async () => {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    let req: TestIpAddressUsingGETRequest = {};
    req.ipAddress = data.ip;
    new WhiteListIpControllerApi(genApiConfig()).testIpAddressUsingGET(req)
      .then((res: SuccessResponseModel) => {
        if (res.success) {
          setIsWhiteListIp(res.success);
        }
      });
    // setCurrentIp(data.ip);
  };

  useEffect(() => {
    // console.log("socket", socketMsg);
    let bookObj = JSON.stringify({
      bookId: parseInt(params.id!),
    });
    setDetailPage(bookObj);

    if (socketMsg !== undefined) {
      if (
        Object.keys(socketMsg?.detailPage!!).length > 0 ||
        Object.keys(socketMsg?.bookPage!!).length > 0
      ) {
        //console.log("bookDetail", socketMsg);
        for (let key in socketMsg.bookPage) {
          if (key === params.id!) {
            console.log("socketMsg?.bookPage[key].length >= onlineReadSametime", socketMsg?.bookPage[key].length >= onlineReadSametime);
            if (socketMsg?.bookPage[key].length >= onlineReadSametime) {
              setHiddenOnlineRead(true);
            } else {
              setHiddenOnlineRead(false);
            }
          }
          // console.log("bookDetail1", socketMsg?.detailPage[key]);
          // console.log("bookDetail2", key);
        }
      }
    }
  }, [socketMsg]);

  useEffect(() => {
    //console.log("props book detail", props.userInfo);
    //console.log("props book detail", props.userInfo);
    //console.log("props count", Object.keys(props.userInfo));

    fetchIpAddress();

    setCurrerntPage(1);
    if (props.userInfo && Object.keys(props.userInfo).length > 0) {
      if (props.userInfo.member.email === undefined) {
        setBookingEmail("");
      } else {
        setBookingEmail(props.userInfo.member.email);
      }
    }

    let getOnlineReadSametimeUsingGETRequest:GetOnlineReadSametimeUsingGETRequest = {
      productId: parseInt(params.id!)
    };
    new SystemSettingControllerApi(genApiConfig())
      .getOnlineReadSametimeUsingGET(getOnlineReadSametimeUsingGETRequest)
      .then((res: number) => {
        setOnlineReadSametime(res)
      })
  }, []);

  const saveReportRecordReportTypeContentUsingPOST = () => {
    // saveReportRecordUsingPOST reportType Content
    if (localStorage.getItem(PATH.USER_INFO) !== null) {
      new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
        memberId: props.userInfo?.member?.memberId,
        productId: parseInt(params.id!),
        reportType: SaveReportRecordUsingPOSTReportTypeEnum.Content,
        bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
      });
    }
  }
  const getApiData = () => {
    /*
    saveReportRecordUsingPOST();
    */
    new BorrowControllerApi(genApiConfig())
      .productBorrowStatusUsingPOST({ productId: parseInt(params.id!) })
      .then((data) => setProductBorrowStatus(data));
    new ProductControllerApi(genApiConfig())
      .getProductByProductIdUsingGET({ productId: parseInt(params.id!) })
      .then((data: any) => {
        // console.log("DDDDD", data);
        setDisplayData(data);
      });
    new ProductCommentControllerApi(genApiConfig())
      .getProductCommentByProductIdUsingGET({
        productId: parseInt(params.id!),
        productCommentPage: currentPage,
        productCommentSize: 5,
      })
      .then((data: any) => {
        setCommentDisplay(data.list);
        setPagenum(data.pagenum);
      });

    new BorrowControllerApi(genApiConfig())
      .listReserveByBookUsingGET({
        productId: parseInt(params.id!),
      } as ListReserveByBookUsingGETRequest)
      .then((data: Array<ReserveBorrow>) => {
        console.log("listReserveByBookUsingGET", data);
        setReserveList(data);
      });

    if (localStorage.getItem(PATH.USER_INFO) !== null) {
      let member = JSON.parse(localStorage.getItem(PATH.USER_INFO)!!);

      if (!member.isAdmin) {
        new BorrowControllerApi(genApiConfig())
          .listAllMyReserveBookUsingPOST({
            memberId: member.member.memberId,
          } as ListAllMyReserveBookUsingPOSTRequest)
          .then((data: any) => {
            props.myReserveBookList(data);
          });
        new BorrowControllerApi(genApiConfig())
          .listAllMyBorrowUsingPOST({
            memberId: member.member.memberId,
            pageNo: 0,
            pageSize: 50,
          } as ListAllMyBorrowUsingPOSTRequest)
          .then((data: any) => {
            props.myBorrowBookList(data.content);
          });
        new MemberControllerApi(genApiConfig())
          .getMemberFavouritesByMemberIdProdIdUsingGET({
            memberId: member.member.memberId,
            productId: parseInt(params.id!),
          })
          .then((data: any) => {
            if (data.length > 0) {
              setBookMarked(true);
            }
          });
      }
    }
  };

  useEffect(() => {
    saveReportRecordReportTypeContentUsingPOST();
    getApiData();
    if (currentPage === 1) {
      setPageStatusStart(false);
    } else {
      setPageStatusStart(true);
    }

    if (currentPage < pagenum) {
      setPageStatusEnd(true);
    } else {
      setPageStatusEnd(false);
    }
  }, [params.id!, currentPage, pagenum]);

  useEffect(() => {
    rootElement && rootElement.scrollTo(0, 0);
    setBookMarked(
      props.bookMarkList.bookMarkList.filter(
        (it: any) => it.productId == params.id!
      ).length > 0
    );
    setCurrerntPage(1);
  }, [pathname]);

  const emailComfirm = () => {
    // if (bookingEmail === "" || validateEmail(bookingEmail) === null) {
    //   alert("請輸入有效的電郵資料");
    //   return;
    // }

    reserveBookComfirm(params.id!!);
  };

  const handletoggle = (value: any) => {
    switch (value) {
      case "info":
        //console.log(value);
        setToggleInfo(value);
        break;
      case "comment":
        setToggleInfo(value);
        break;
      case "author":
        setToggleInfo(value);
        break;
    }
  };

  const fileExtension = (apiDate: string) => {
    return apiDate.split(".").pop();
  };

  const convertDate = (fileName: string) => {
    //console.log("fileName", typeof fileName);
    return `${fileName.split(" ")[4]}年${fileName.split(" ")[2]}月`;
  };

  const handleReserveBook = (productId: string) => {
    if (props.auth === "" || props.auth === null) {
      setShowConfirmDialog(true);
      setConfirmDialogMessage("請先登入");
      // alert("請先登入");

      // if (
      //   process.env.REACT_APP_BACKEND == "https://suepsso.octopus-tech.com" ||
      //   window.location.host.includes("hkpl") ||
      //   process.env.REACT_APP_CLIENT_ID === "hkpl"
      // ) {
      //   window.parent.parent.location = PATH.HKPL_SSO_PATH;
      // } else {
      //   navigate(PATH.LOGIN_PATH);
      // }
      return;
    }

    const reserve = props.reserveBookList.reserveBookList.find(
      (it: any) => it.productId == params.id
    );

    if (reserve !== undefined) {
      alert("此書已在你的書架內");
    } else {
      setOpenReserveDialog(true);
      setOpenEmailDialog(true);
      //setOpenReserveDialog(true);
    }
  };

  const reserveBookComfirm = (productId: string) => {
    new BorrowControllerApi(genApiConfig())
      .reservedBookMoreThanMaxUsingPOST({
        memberId: props.userInfo?.member?.memberId,
      })
      .then((res: SuccessResponseModel) => {
        if (res.success) {
          setReserveBookMaxDialog(true);
        } else {
          new BorrowControllerApi(genApiConfig())
            .reserveBookUsingPOST({
              memberId: props.userInfo?.member?.memberId,
              productId: parseInt(productId),
              email: bookingEmail,
              needSend: needEmail,
            } as ReserveBookUsingPOSTRequest)
            .then((data) => {
              // console.log("datbbbbb", data);

              let flag = false;
              if (data !== undefined && data !== null) {
                alert("成功預約");
                setOpenReserveDialog(false);
                setBookingEmail("");
                setOpenEmailDialog(false);
                flag = true;
              }
              getApiData();
              return flag;
            })
            .then((flagResult) => {
              if (flagResult) {
                new ReportRecordControllerApi(
                  genApiConfig()
                ).saveReportRecordUsingPOST({
                  memberId: props.userInfo?.member.memberId,
                  productId: parseInt(params.id!),
                  reportType:
                    SaveReportRecordUsingPOSTReportTypeEnum.BorrowTime,
                  bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
                });
              }
            })
            .then(() =>
              new BorrowControllerApi(genApiConfig())
                .listAllMyReserveBookUsingPOST({
                  memberId: props.userInfo?.member.memberId,
                } as ListAllMyReserveBookUsingPOSTRequest)
                .then((data: any) => {
                  //console.log("dataaaaaaaa", data);
                  props.myReserveBookList(data);
                })
            )
            .catch((err) => {
              err.json().then((er: any) => {
                //alert(er.message);
                console.log("reserveBookComfirm", er.message);
                setBookingEmail("");
                setOpenEmailDialog(false);
              });
            });
        }
      });
    getApiData();
    setBookingEmail("");
    setOpenEmailDialog(false);
  };

  const borrowBookComfirm = () => {
    let requestQuery = {} as ProductBorrowMyStatusUsingPOSTRequest;

    requestQuery.memberId = props.userInfo.member.memberId;
    requestQuery.productId = parseInt(params.id!);

    //console.log("borrowBookComfirm", props);
    new BorrowControllerApi(genApiConfig())
      .productBorrowMyStatusUsingPOST(requestQuery)
      .then((res: MyBorrowStatus) => {
        switch (res.status) {
          case MyBorrowStatusStatusEnum.AlreadyBorrow:
            return alert("書本已在書架");
          case MyBorrowStatusStatusEnum.CanBorrow:
            new BorrowControllerApi(genApiConfig())
              .borrowedBookMoreThanMaxUsingPOST({
                memberId: props.userInfo?.member.memberId,
              })
              .then((res: SuccessResponseModel) => {
                if (res.success) {
                  setBorrowBookMaxDialog(true);
                } else {
                  new BorrowControllerApi(genApiConfig())
                    .borrowBookUsingPOST({
                      memberId: props.userInfo?.member.memberId,
                      productId: parseInt(params.id!),
                    } as BorrowBookUsingPOSTRequest)
                    .then((data: BorrowDto) => {
                      let flag = false;
                      if (data.borrowId !== 0) {
                        setOpenBorrowDialog(false);
                        flag = true;
                        new BorrowControllerApi(genApiConfig())
                          .productBorrowStatusUsingPOST({
                            productId: parseInt(params.id!),
                          })
                          .then((data) => setProductBorrowStatus(data));
                        alert("成功借書");
                      }
                      return flag;
                    })
                    .then((flagResult) => {
                      //console.log("flagResult", flagResult);
                      if (flagResult) {
                        new ReportRecordControllerApi(
                          genApiConfig()
                        ).saveReportRecordUsingPOST({
                          memberId: props.userInfo?.member.memberId,
                          productId: parseInt(params.id!),
                          reportType:
                            SaveReportRecordUsingPOSTReportTypeEnum.WebBorrow,
                          bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
                        });
                      }
                    })
                    .then(() =>
                      new BorrowControllerApi(genApiConfig())
                        .listAllMyBorrowUsingPOST({
                          memberId: props.userInfo?.member.memberId,
                          pageNo: 0,
                          pageSize: 50,
                        } as ListAllMyBorrowUsingPOSTRequest)
                        .then((data: any) => {
                          props.myBorrowBookList(data.content);
                        })
                    )
                    .catch((err) => {
                      console.log("borrowBookComfirm", err);
                    });
                }
              });

            break;
          case MyBorrowStatusStatusEnum.CanReserve:
            new ReportRecordControllerApi(
              genApiConfig()
            ).saveReportRecordUsingPOST({
              memberId: props.userInfo?.member.memberId,
              productId: parseInt(params.id!),
              reportType: SaveReportRecordUsingPOSTReportTypeEnum.CantBorrow,
              bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
            });
            return alert("書本沒有剩餘，是否預約");
          case MyBorrowStatusStatusEnum.AlreadyReserve:
            return alert("書本已經預約");
        }
      })
      .catch((err: any) => {
        console.log(err);
      });

    getApiData();
    setOpenBorrowDialog(false);
  };

  useEffect(() => {
    let readerConfig = localStorage.getItem("readerConfig");

    if (readerConfig !== null) {
      let readerConfigObj = JSON.parse(readerConfig);

      if (props.reader.mode !== "" && props.reader.mode !== undefined) {
        readerConfigObj["rm"] = props.reader.mode.substring(0, 1);
        localStorage.setItem("readerConfig", JSON.stringify(readerConfigObj));
        if (props.reader.mode === "read") {
          setLoadingProd(true);
          props.handleReaderMode("");
        }

        if (props.reader.mode === "preview") {
          setLoadingPreview(true);
          props.handleReaderMode("");
        }

        if (props.reader.mode === "online") {
          setLoadingOnlineRead(true);
          props.handleReaderMode("");
        }
      }
    }
  }, [props]);

  const handleReadBook = (actionType: string) => {
    if (
      actionType === "read" ||
      actionType === "preview" ||
      actionType === "online"
    ) {
      props.handleReaderMode(actionType);
      //setReadAction(actionType);
    } else {
      setLoadingOnlineRead(true);
    }

	/*
    if (props.userInfo?.member !== undefined) {
      new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
        memberId: props.userInfo?.member.memberId,
        productId: parseInt(params.id!),
        reportType: SaveReportRecordUsingPOSTReportTypeEnum.ReadOnline,
        bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
      });
    }
    */
  };

  const handlePreViewBook = () => {
    setLoadingPreview(true);
    new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
      memberId: props.userInfo?.member.memberId,
      productId: parseInt(params.id!),
      reportType: SaveReportRecordUsingPOSTReportTypeEnum.Preview,
      bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
    });
  };

  const openBookClick = (bookObj: any, fileName: string) => {
    //console.log("openBookClick", bookObj, fileName);
    if (StorageUtil.getReaderConfig("isOpenInMain") === "yes") {
      //props.history.push(BookUtil.getBookUrl(book));
      //props.handleReadingBook(book);
    } else {
      //localStorage.setItem("tempBook", JSON.stringify(book));

      BookUtil.RedirectBook(bookObj, fileName, parseInt(params.id!)).then(
        () => {
          if (
            localStorage.getItem("openBookUrl") !== undefined &&
            localStorage.getItem("openBookUrl") !== null &&
            localStorage.getItem("openBookUrl") !== ""
          ) {
            setConfirmOpenBookDialog(true);
          }
        }
      );
    }
    setLoadingPreview(false);
    setLoadingProd(false);
    setLoadingOnlineRead(false);
  };

  useEffect(() => {
    if (loadingPreview) {
      openBook();
    }
  }, [loadingPreview]);

  useEffect(() => {
    if (loadingProd || loadingOnlineRead) {
      openBook();
    }
  }, [loadingProd, loadingOnlineRead]);

  const openBook = () => {
    var item = displayData as IProductAllListModel;

    if (item["ePubProdFileName"] !== undefined) {
      var fileName = item["ePubProdFileName"];

      var fileExtension = getFileExtension(fileName);

      const readerConfig = localStorage.getItem("readerConfig");

      let reportType = "" as SaveReportRecordUsingPOSTReportTypeEnum;

      if (readerConfig !== null) {
        const readerConfigObj = JSON.parse(readerConfig);
        if (readerConfigObj.rm === "r" || readerConfigObj.rm === "o") {
          reportType = SaveReportRecordUsingPOSTReportTypeEnum.ReadOnline;
        }

        if (readerConfigObj.rm === "p") {
          reportType = SaveReportRecordUsingPOSTReportTypeEnum.Preview;
        }
      }

      let memberId = 1;

      if (props.userInfo.member !== undefined) {
        memberId = props.userInfo.member.memberId;
      }

      new ReportRecordControllerApi(genApiConfig()).saveReportRecordUsingPOST({
        memberId: memberId,
        productId: item.productId,
        reportType: reportType,
        bookType:
          fileExtension === "mp3" || fileExtension === "mp4"
            ? SaveReportRecordUsingPOSTBookTypeEnum.Audio
            : SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
      });

      if (fileExtension === "mp3") {
        var audioDataList = JSON.parse(
          item["multimediaFileNames"] as unknown as string
        );
        var formattedDataList: Array<any> = [];
        audioDataList
          .sort((a: any, b: any) => {
            return a["Seq"] - b["Seq"];
          })
          .map((chapter: any) => {
            let chapterData: any = {};
            chapterData["title"] = chapter["Title"];
            chapterData["audioFilePath"] = chapter["File"];
            chapterData["srtFilePath"] = chapter["Subtitle"];
            chapterData["covers"] = [
              { coverFilePath: chapter["Cover"], appearTime: 0 },
            ];
            formattedDataList.push(chapterData);
          });
        var formattedData: any = { list: formattedDataList };
        formattedData["bookname"] = displayData && displayData.productNameChi;
        formattedData["author"] = displayData && displayData.author;
        formattedData["cover"] = displayData && displayData.coverFileName;
        formattedData["bookIsbn"] = displayData && displayData.bookIsbn;
        localforage.setItem("audio", formattedData);
        localStorage.setItem(
          "openBookUrl",
          `${window.location.href.split("/")[0]}/audioreader/${displayData.bookIsbn
          }?bookId=${parseInt(params.id!)}`
        );
        setLoadingPreview(false);
        setLoadingProd(false);
        setLoadingOnlineRead(false);
        setConfirmOpenBookDialog(true);

        return;
      }

      if (fileExtension === "mp4") {
        var videoDataList = JSON.parse(
          item["multimediaFileNames"] as unknown as string
        );
        var formattedDataList: Array<any> = [];
        videoDataList
          .sort((a: any, b: any) => {
            return a["Seq"] - b["Seq"];
          })
          .map((chapter: any) => {
            let chapterData: any = {};
            chapterData["title"] = chapter["Title"];
            chapterData["videoFilePath"] = chapter["File"];
            chapterData["srtFilePath"] = chapter["Subtitle"];
            chapterData["covers"] = [
              { coverFilePath: chapter["Cover"], appearTime: 0 },
            ];
            formattedDataList.push(chapterData);
          });
        var formattedData: any = { list: formattedDataList };
        formattedData["bookname"] = displayData && displayData.productNameChi;
        formattedData["author"] = displayData && displayData.author;
        formattedData["cover"] = displayData && displayData.coverFileName;
        formattedData["bookIsbn"] = displayData && displayData.bookIsbn;
        localforage.setItem("video", formattedData);
        localStorage.setItem(
          "openBookUrl",
          `${window.location.href.split("/")[0]}/videoreader/${displayData.bookIsbn
          }?bookId=${parseInt(params.id!)}`
        );
        setLoadingPreview(false);
        setLoadingProd(false);
        setLoadingOnlineRead(false);
        setConfirmOpenBookDialog(true);

        return;
      }

      api.getEncryptionFileByString(
        displayData.sapArticleNumber,
        "ePubProd",
        fileName
      ).then(async (blob: any) => {
        var file = new File([blob], fileName, {
          lastModified: new Date().getTime(),
        });

        await getMd5WithBrowser(file).then(async (md5) => {
          if (fileExtension === "pdf") {
            let reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onload = async (event) => {
              const file_content = event?.target?.result;
              BookUtil.generateBook(
                fileName,
                fileExtension,
                md5,
                blob.size,
                fileName,
                file_content
              ).then(async (bookObj) => {
                //console.log("ffff", bookObj);
                await BookUtil.addBook(bookObj.key, file_content);
                RecentBooks.setRecent(bookObj.key);
                localforage.setItem("books", bookObj);
                openBookClick(bookObj, item.productNameChi);
              });
            };
          }

          if (fileExtension === "epub") {
            addEpub(file, md5, fileName).then(async (bookObj) => {
              await BookUtil.addBook(bookObj.key, file);
              RecentBooks.setRecent(bookObj.key);
              localforage.setItem("books", bookObj);
              openBookClick(bookObj, item.productNameChi);
            });
          }
        });
      });
    }
  };

  // const openBook = () => {
  //   var item = displayData as IProductAllListModel;

  //   if (item["ePubProdFileName"] !== undefined) {
  //     var fileName = item["ePubProdFileName"];
  //     api.getFileByFileName(fileName).then((fileResult: any) => {
  //       fileResult.blob().then(async (blob: any) => {
  //         var file = new File([blob], fileName, {
  //           lastModified: new Date().getTime(),
  //         });

  //         var fileExtension = getFileExtension(fileName);

  //         await getMd5WithBrowser(file).then(async (md5) => {
  //           if (fileExtension === "pdf") {
  //             let reader = new FileReader();
  //             reader.readAsArrayBuffer(blob);
  //             reader.onload = async (event) => {
  //               const file_content = event?.target?.result;
  //               BookUtil.generateBook(
  //                 fileName,
  //                 fileExtension,
  //                 md5,
  //                 blob.size,
  //                 fileName,
  //                 file_content
  //               ).then(async (bookObj) => {
  //                 //console.log("ffff", bookObj);
  //                 await BookUtil.addBook(bookObj.key, file_content);
  //                 RecentBooks.setRecent(bookObj.key);
  //                 localforage.setItem("books", bookObj);
  //                 openBookClick(bookObj, item.productNameChi);
  //               });
  //             };
  //           }

  //           if (fileExtension === "epub") {
  //             addEpub(file, md5, fileName).then(async (bookObj) => {
  //               await BookUtil.addBook(bookObj.key, file);
  //               RecentBooks.setRecent(bookObj.key);
  //               localforage.setItem("books", bookObj);
  //               openBookClick(bookObj, item.productNameChi);
  //             });
  //           }
  //         });

  //         let reportType = "" as SaveReportRecordUsingPOSTReportTypeEnum;

  //         if (props.reader.mode === "read" && props.reader.mode === "online") {
  //           reportType = SaveReportRecordUsingPOSTReportTypeEnum.ReadOnline;
  //         }

  //         if (props.reader.mode === "preview") {
  //           reportType = SaveReportRecordUsingPOSTReportTypeEnum.Preview;
  //         }

  //         if (props.userInfo?.member !== undefined) {
  //           new ReportRecordControllerApi(
  //             genApiConfig()
  //           ).saveReportRecordUsingPOST({
  //             memberId: props.userInfo?.member.memberId,
  //             productId: props.props.productId,
  //             reportType: reportType,
  //             bookType: SaveReportRecordUsingPOSTBookTypeEnum.Ebook,
  //           });
  //         }
  //       });
  //     });
  //   }
  // };

  const handlePostComment = () => {
    if (localStorage.getItem(PATH.USER_INFO) === null) {
      setShowConfirmDialog(true);
      setConfirmDialogMessage("請先登入");
      return;
      //return alert(`請先登入`);
    }

    if (props.userInfo.isAdmin) {
      return alert("請使用學生用戶");
    }

    if (message === "") {
      return alert("請輸入評論");
    }
    if (rating === undefined || rating === 0) {
      return alert("請輸入評分");
    }
    const body: { comment: string; rating: number } = {
      comment: message,
      rating: rating,
    };
    //console.log("body", body);
    new ProductCommentControllerApi(genApiConfig())
      .insertProductCommentUsingPOST({
        memberId: props.userInfo?.member.memberId,
        productId: parseInt(params.id!),
        productCommentPayloadModel: body,
      } as InsertProductCommentUsingPOSTRequest)
      .then((data) => {
        if (data.success) {
          new ProductControllerApi(genApiConfig())
            .getProductByProductIdUsingGET({ productId: parseInt(params.id!) })
            .then((data: any) => {
              setDisplayData(data);
              //console.log(data);
              setCommentDisplay(data.productComments);
            });
          setMessage("");
          setRating(0);
        }
      });
    // api.postCommentMessage("4", params.id!.toString(), body).then((data) => {
    //   console.log(data);
    // });
  };

  const handlePreviousPgae = () => {
    if (currentPage === 1) return;
    setCurrerntPage(currentPage - 1);
  };

  const handleNextPgae = () => {
    if (currentPage === pagenum) return;
    setCurrerntPage(currentPage + 1);
  };

  const handleBookMarked = () => {
    if (localStorage.getItem(PATH.USER_INFO) === null) {
      setShowConfirmDialog(true);
      setConfirmDialogMessage("請先登入");
      // alert("請先登入");
      // if (
      //   process.env.REACT_APP_BACKEND == "https://suepsso.octopus-tech.com" ||
      //   window.location.host.includes("hkpl") ||
      //   process.env.REACT_APP_CLIENT_ID === "hkpl"
      // ) {
      //   window.parent.parent.location = PATH.HKPL_SSO_PATH;
      // } else {
      //   navigate(PATH.LOGIN_PATH);
      // }

      return;
    }

    console.log(
      "handleBookMarked",
      JSON.parse(localStorage.getItem(PATH.USER_INFO)!).isAdmin
    );
    if (JSON.parse(localStorage.getItem(PATH.USER_INFO)!).isAdmin) {
      // if (props.userInfo.isAdmin) {
      return alert("請使用學生用戶");
    }

    if (bookMarked) {
      new MemberControllerApi(genApiConfig())
        .deleteMemberFavouriteByMemberIdUsingDELETE({
          memberId: props.userInfo?.member.memberId,
          productId: parseInt(params.id!),
        } as DeleteMemberFavouriteByMemberIdUsingDELETERequest)
        .then((data) => {
          if (data) {
            setBookMarked(false);
            alert(t("RemoveBookmark"));
            new MemberControllerApi(genApiConfig())
              .getMemberFavouritesByMemberIdUsingGET({
                memberId: props.userInfo?.member.memberId,
              } as GetMemberFavouritesByMemberIdUsingGETRequest)
              .then((data: Array<ProductDto>) => {
                props.myBookMarkList(data);
                //console.log("nonononono", data);
              });
          }
        });
      return;
    }

    new MemberControllerApi(genApiConfig())
      .addMemberFavouriteByMemberIdUsingPUT({
        memberId: props.userInfo?.member.memberId,
        productId: parseInt(params.id!),
      } as AddMemberFavouriteByMemberIdUsingPUTRequest)
      .then((data) => {
        if (data) {
          setBookMarked(true);
          alert(t("AddBookmark"));
          setOpenBookmarkDialog(true);
          new MemberControllerApi(genApiConfig())
            .getMemberFavouritesByMemberIdUsingGET({
              memberId: props.userInfo?.member.memberId,
            } as GetMemberFavouritesByMemberIdUsingGETRequest)
            .then((data) => {
              if (data) {
                setBookMarked(true);
                new MemberControllerApi(genApiConfig())
                  .getMemberFavouritesByMemberIdUsingGET({
                    memberId: props.userInfo?.member.memberId,
                  } as GetMemberFavouritesByMemberIdUsingGETRequest)
                  .then((data) => {
                    props.myBookMarkList(data);
                    //console.log("hehehehe", data);
                  });
              }
            });
        }
      });
  };

  const checkLogined = () => {
    if (localStorage.getItem(PATH.USER_INFO) === null) {
      setShowConfirmDialog(true);
      setConfirmDialogMessage("請先登入");
      // alert("請先登入");
      // if (
      //   process.env.REACT_APP_BACKEND == "https://suepsso.octopus-tech.com" ||
      //   window.location.host.includes("hkpl") ||
      //   process.env.REACT_APP_CLIENT_ID === "hkpl"
      // ) {
      //   window.parent.parent.location = PATH.HKPL_SSO_PATH;
      // } else {
      //   navigate(PATH.LOGIN_PATH);
      // }
      return;
    }

    if (JSON.parse(localStorage.getItem(PATH.USER_INFO)!).isAdmin) {
      alert("請使用學生用戶");
      return;
    }

    setOpenBorrowDialog(true);
  };

  const handleCancelReserveBook = () => {
    new BorrowControllerApi(genApiConfig())
      .cancelReservedBookUsingDELETE({
        memberId: props.userInfo?.member.memberId,
        productId: parseInt(params.id!),
      })
      .then((data) => {
        if (data.status !== undefined) {
          alert(`書本 ${displayData.productNameChi} 的預約己取消`);
          getApiData();
        }
      });
  };

  // const onlineRead = () => {
  //   handleReadBook();
  // };

  // useEffect(() => {
  //   let user = localStorage.getItem("userInfo");
  //   console.log("user", user);
  //   if (onlineReadBook) {
  //     if (socket === null) {
  //       console.log("connect");
  //       socket = io(`ws://192.168.2.200:8888`);

  //       socket.emit("ssss");

  //       socket.on("sendMsg", (message: any) => {
  //         setSocketMsg(message);
  //       });

  //       // socket.on("receiveMsg", (message: any) => {
  //       //   setSocketMsg(message);
  //       //   console.log("receiveMsg msg", message);
  //       // });
  //     }
  //   }

  //   return () => {
  //     if (socket !== null) {
  //       socket.disconnect();
  //       socket.off();
  //       socket = null;
  //     }
  //   };
  // }, [onlineReadBook]);

  // useEffect(() => {
  //   console.log("socketMSG", socketMsg);
  // }, [socketMsg, socket]);

  return (
    //  <div className={style.BookDetailLayout}>
    //     <div className={style.BookDetailInnerLayout}>
    <>
      <div className={style.BookDetailHeader}>
        <div className={style.backHomeButtonContainer}>
          <div>
            <Button
              styleType={"upperNavbarButton"}
              callBack={(value) => navigate(-1)}
            >
              <img src={arrowBack} />
              <Trans>PreviousPage</Trans>
            </Button>
          </div>
        </div>
        <div className={style.category}>
          {/*
          <div className={style.categoryButtonContainer}>
            <Button styleType={"upperNavbarButton"} callBack={() => console.log(1)}>
              全部分類
            </Button>
          </div>
          <div> &nbsp; &nbsp;/&nbsp; &nbsp;</div>
          <div className={style.categoryButtonContainer}>
            <Button styleType={"upperNavbarButton"} callBack={() => console.log(1)}>
              中二必讀
            </Button>
          </div>
          */}
          {/* <div> &nbsp; &nbsp;/&nbsp; &nbsp;</div> */}
          {/* <div>{displayData.productNameChi}</div> */}
          <div>
            {i18n.language === "cn"
              ? displayData.productNameChi &&
              converter(displayData.productNameChi)
              : displayData.productNameChi && displayData.productNameChi}
          </div>
        </div>
      </div>
      <div className={style.pageContentLayout}>
        <div className={style.bookDetailsContainer}>
          <div className={style.bookLayout}>
            <div className={style.bookImgContainer}>
              {displayData.coverFileName &&
                displayData.coverFileName !== undefined &&
                displayData.coverFileName !== "" ? (
                <img src={api.getFileUrl(displayData.sapArticleNumber, "cover", displayData.coverFileName, "x-oss-process=image/resize,h_200")}/>
              ) : (
                <></>
              )}
            </div>

            <div className={style.buttonGroup}>
              <div className="borrow">
                {productBorrowStatus &&
                  productBorrowStatus.canBorrowCopy > 0 ? (
                  props.borrowBookList.borrowBookList.find(
                    (it: any) => it.productId == params.id
                  ) !== undefined ? (
                    <></>
                  ) : (
                    <div className={style.btnGroupDiv}>
                      <Button
                        styleType={"upperNavbarButton"}
                        callBack={() => checkLogined()}
                      >
                        <Trans>Borrow</Trans>
                      </Button>
                    </div>
                  )
                ) : props.borrowBookList.borrowBookList.find(
                  (it: any) => it.productId == params.id
                ) !== undefined ? (
                  <div className={style.btnGroupDiv}>
                    <Button
                      styleType={"upperNavbarButton"}
                      isLoading={loadingProd}
                      callBack={() => handleReadBook("read")}
                    >
                      <Trans>Read</Trans>
                    </Button>
                  </div>
                ) : reserveList.find(
                  (it: ReserveBorrow) =>
                    it.memberId == props.userInfo?.member?.memberId
                ) === undefined ? (
                  <div className={style.btnGroupDiv}>
                    {props.userInfo !== undefined &&
                      JSON.stringify(props.userInfo) !== "{}" ? (
                      <>
                        <Button
                          styleType={"upperNavbarButton"}
                          callBack={() => {
                            if (
                              JSON.parse(localStorage.getItem(PATH.USER_INFO)!)
                                .isAdmin
                            ) {
                              // if (props.userInfo.isAdmin) {
                              return alert("請使用學生用戶");
                            }
                            //setOpenReserveDialog(true)
                            setOpenEmailDialog(true);
                            //console.log("bugshere", props.userInfo);
                          }}
                        >
                          <Trans>Booking</Trans>
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          styleType={"upperNavbarButton"}
                          callBack={() => {
                            setShowConfirmDialog(true);
                            setConfirmDialogMessage("請先登入");
                            // alert("請先登入");
                            // if (
                            //   process.env.REACT_APP_BACKEND ==
                            //     "https://suepsso.octopus-tech.com" ||
                            //   window.location.host.includes("hkpl") ||
                            //   process.env.REACT_APP_CLIENT_ID === "hkpl"
                            // ) {
                            //   window.parent.parent.location =
                            //     PATH.HKPL_SSO_PATH;
                            // } else {
                            //   navigate(PATH.LOGIN_PATH);
                            // }
                          }}
                        >
                          <Trans>Booking</Trans>
                        </Button>
                      </>
                    )}
                  </div>
                ) : (
                  <div className={style.btnGroupDiv}>
                    <Button
                      styleType={"upperNavbarButton"}
                      callBack={() => {
                        //setOpenReserveDialog(true)
                        // setOpenEmailDialog(true);
                        //console.log("bugshere", props.userInfo);
                        handleCancelReserveBook();
                      }}
                    >
                      <Trans>CancelBooking</Trans>
                    </Button>
                  </div>
                )}

                {localStorage.getItem(PATH.USER_INFO) !== null && props.borrowBookList.borrowBookList.find(
                  (it: any) => it.productId == params.id
                ) !== undefined ? (
                  <></>
                ) : (
                  <>
                    <div>
                      <Button
                        styleType={"upperNavbarButton"}
                        loadingStyle={{ width: "20px", height: "20px" }}
                        isLoading={loadingPreview}
                        callBack={() => handleReadBook("preview")}
                      >
                        <Trans>Preview</Trans>
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <div style={{ marginTop: "4px" }}>
                {
                  isWhiteListIp || (props.userInfo?.member?.memberId && props.borrowBookList.borrowBookList.find(
                    (it: any) => it.productId == params.id
                  ) === undefined) ? (
                    <Button
                      styleType={"upperNavbarButton"}
                      loadingStyle={{ width: "20px", height: "20px" }}
                      isLoading={loadingOnlineRead}
                      disabled={hiddenOnlineRead}
                      callBack={() => handleReadBook("online")}
                      customStyle={{ minWidth: "50px" }}
                    >
                      {
                        hiddenOnlineRead ? (
                          <>
                            <Trans>OnlineReadFull</Trans>
                          </>
                        ) : (
                          <>
                            <Trans>OnlineRead</Trans>
                          </>
                        )
                      }
                    </Button>
                  ) : <></>
                }


                {props.userInfo !== undefined ? (
                  <div className={style.bookmark}>
                    <img
                      style={{ width: "30px", height: "30px" }}
                      src={bookMarked ? bookmarkon : bookmarkOff}
                      onClick={() => {
                        handleBookMarked();
                        //console.log("bugshere", props.userInfo);
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <img
                      src={bookMarked ? bookmarkon : bookmarkOff}
                      onClick={() => {
                        setShowConfirmDialog(true);
                        setConfirmDialogMessage("請先登入");
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={style.bookDetailLayout}>
            <h3>
              {i18n.language === "cn"
                ? displayData.productNameChi &&
                converter(displayData.productNameChi)
                : displayData.productNameChi && displayData.productNameChi}
            </h3>
            {/* <h3>{i18n.language === "cn" ? displayData && converter(displayData.productNameChi) : displayData && displayData.productNameChi}</h3> */}
            {/* <div className={style.star} style={{ cursor: "pointer" }}>
              <Rating modify={false} rate={displayData && displayData.rating} />
            </div>
            <span className={style.clickRate}>
              <Trans>ClickRate</Trans>
            </span> */}
            <div className={style.flex}>
              {displayData.author &&
                displayData.author.split(":::").map((it: any) => (
                  <>
                    {it.split(/[、,， ]+/).length != 1 ? (
                      <>
                        {it.split(/[、,， ]+/).map((o: any) => (
                          <>
                            <p
                              className={style.author}
                              onClick={() =>
                                navigate(
                                  `/search/result?inputOption=author&keyword=${o}`
                                )
                              }
                            >
                              {o}
                            </p>
                            &nbsp;
                          </>
                        ))}
                      </>
                    ) : (
                      <p
                        className={style.author}
                        onClick={() =>
                          navigate(
                            `/search/result?inputOption=author&keyword=${it}`
                          )
                        }
                      >
                        {it && it} &nbsp;
                      </p>
                    )}
                    {/* <p className={style.author} onClick={() => navigate(`/search/result?keyword=${it}`)}>
                      {it && it} &nbsp;
                      {it && it.split("、").length != 0 ? it.split("、")[0] : it}
                    </p> */}
                  </>
                ))}
            </div>
            <div className={style.subDetails}>
              <div>
                <p>
                  <Trans>Organization</Trans>
                </p>
                <p
                  style={{ color: "rgba(181 60 63)", cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/search/result?organizationId=${displayData.publishHouse.organizationId}`
                    )
                  }
                >
                  {/* {displayData.publishHouse && displayData.publishHouse.name && displayData.publishHouse.name} */}
                  {i18n.language === "cn"
                    ? displayData.publishHouse &&
                    displayData.publishHouse.name &&
                    converter(displayData.publishHouse.name)
                    : displayData.publishHouse &&
                    displayData.publishHouse.name &&
                    displayData.publishHouse.name}
                </p>
              </div>
              <div>
                <p>
                  <Trans>PublishDate</Trans>:
                </p>
                <p>
                  {displayData?.pPublishDate &&
                    dateToString(displayData.pPublishDate, "yyyy年MM月")}
                </p>
              </div>
              <div>
                <p>
                  <Trans>Format</Trans>:
                </p>
                <p>
                  {displayData.ePubProdFileName &&
                    fileExtension(displayData.ePubProdFileName)}
                </p>
              </div>
              <div>
                <p>
                  <Trans>Category</Trans>:
                </p>
                {/* <div style={{ display: "flex" }}> */}
                <div className={style.subDetailscategory}>
                  {displayData.categories &&
                    displayData.categories.map((it: any) => (
                      <p
                        style={{ color: "rgba(181 60 63)", cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/search/result?categoryId=${it.categoryId}`)
                        }
                      >
                        {/* {it.categoryName} */}
                        {i18n.language === "cn"
                          ? converter(it.categoryName)
                          : it.categoryName}
                        &nbsp;
                      </p>
                    ))}
                </div>
              </div>
              <div>
                <p>ISBN :</p>
                <p>{displayData.bookIsbn}</p>
              </div>
              <div>
                {displayData.seriesName != undefined ? (
                  <p>
                    <Trans>bookseries</Trans>:
                  </p>
                ) : (
                  <></>
                )}
                <p>
                  {displayData.seriesName != undefined
                    ? displayData.seriesName
                    : ""}
                </p>
                {/* <p>
                  <Trans>Readage</Trans>
                </p> */}
                {/* TODO */}
                {/* <p>借閱次數：</p> */}
              </div>
              {/* <div>

              </div> */}
            </div>
            <h4>
              <>
                {/* {console.log(
                  "props.userInfo??.member?.memberId",
                  props.userInfo??.member?.memberId
                )} */}
                {productBorrowStatus &&
                  productBorrowStatus.canBorrowCopy > 0 ? (
                  <></>
                ) : props.borrowBookList.borrowBookList.find(
                  (it: any) => it.productId == params.id
                ) === undefined ? (
                  reserveList.find(
                    (it: ReserveBorrow) =>
                      it.memberId == props.userInfo?.member?.memberId
                  ) !== undefined ? (
                    <>
                      <Trans>YourBookingSeq</Trans>
                      {reserveList.map((value, index) => {
                        if (
                          value.memberId == props.userInfo?.member?.memberId
                        ) {
                          return index + 1;
                        }
                      })}
                    </>
                  ) : (
                    <>
                      <Trans>BookingCount</Trans>
                      {reserveList.length}
                    </>
                  )
                ) : (
                  <></>
                )}
              </>
            </h4>
          </div>
        </div>
        <div className={style.bookInventoryContainer}>
          <div className={style.bookInventoryDetail}>
            <div>
              {/*
              {process.env.REACT_APP_WEB_NAME === "ldcwkmsslibrary" ? (
                <>
                  <h3>嶺南鍾榮光博士紀念中學</h3>
                </>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "ccmsslibrary" ? (
                <h3>明愛柴灣馬登基金中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "twghcmtslibrary" ? (
                <h3>東華三院張明添中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "pokoklibrary" ? (
                <h3>寶覺中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "lingnanlibrary" ? (
                <h3>嶺南中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "sscclibrary" ? (
                <h3>聖士提反堂中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "pslibrary" ? (
                <h3>五旬節中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "makopanlibrary" ? (
                <h3>馬錦明慈善基金馬可賓紀念中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "twghczmlibrary" ? (
                <h3>東華三院陳兆民中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "pocawsclibrary" ? (
                <h3>寶安商會王少清中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "namslibrary" ? (
                <h3>新亞中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "ldcwkmsslibrary" ? (
                <h3>嶺南鍾榮光博士紀念中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "ntweaclibrary" ? (
                <h3>新界西長者學苑聯網</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "waichowlibrary" ? (
                <h3>上水惠州公立學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "sjalibrary" ? (
                <h3>聖貞德中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "stclarelibrary" ? (
                <h3>聖嘉勒女書院</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "hkcwcclibrary" ? (
                <h3>香港中國婦女會中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "apsktlibrary" ? (
                <h3>九龍塘宣道小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "twgpslibrary" ? (
                <h3>荃灣官立小學 </h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "pkmslibrary" ? (
                <h3>培僑中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "tlmshklibrary" ? (
                <h3>香港真光中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "skhcysslibrary" ? (
                <h3>聖公會陳融中學 </h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "lstlkkclibrary" ? (
                <h3>樂善堂梁銶琚書院 </h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "plktytclibrary" ? (
                <h3>保良局董玉娣中學 </h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "checsslibrary" ? (
                <h3>彩虹邨天主教英文中學 </h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "scslibrary" ? (
                <h3>天主教石鐘山小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "pbpsshlibrary" ? (
                <h3>海怡寶血小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "swhpslibrary" ? (
                <h3>石湖墟公立學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "tcnlibrary" ? (
                <h3>聖公會田灣始南小學 </h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "fsslibrary" ? (
                <h3>福建中學（小西灣）</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "cpcpslibrary" ? (
                <h3>青山天主教小學 </h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "bpslibrary" ? (
                <h3>庇理羅士女子中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "scgawlibrary" ? (
                <h3>東華三院吳祥川紀念中學 </h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "hkpslibrary" ? (
                <h3>知書小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "sueppslibrary" ? (
                <h3>SUEP小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "hksslibrary" ? (
                <h3>知書中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "mkeslibrary" ? (
                <h3>慕光英文書院</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "wcpssslibrary" ? (
                <h3>上水惠州公立學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "ihmclibrary" ? (
                <h3>聖母無玷聖心書院</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "stmatthewlibrary" ? (
                <h3>聖公會聖馬太小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "wsclibrary" ? (
                <h3>僑港伍氏宗親會伍時暢紀念學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "cy2library" ? (
                <h3>中華基督教會全完第二小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "lwfsslibrary" ? (
                <h3>世界龍岡學校劉皇發中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "cccfywlibrary" ? (
                <h3>中華基督教會方潤華小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "ktkclibrary" ? (
                <h3>中華基督教會灣仔堂基道小學(九龍城)</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "kmylslibrary" ? (
                <h3>光明英來學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "sspkwlibrary" ? (
                <h3>深水埔街坊福利會小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "bcwkmslibrary" ? (
                <h3>佛教陳榮根紀念學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "dgslibrary" ? (
                <h3>拔萃女書院</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "lkkpslibrary" ? (
                <h3>樂善堂梁銶琚學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "mwfylibrary" ? (
                <h3>中華基督教會蒙黃花沃紀念小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "ytcpslibrary" ? (
                <h3>仁德天主教小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "fmslibrary" ? (
                <h3>福建中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "hebronlibrary" ? (
                <h3>神召會康樂中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "pccsslibrary" ? (
                <h3>天主教普照中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "np2clibrary" ? (
                <h3>寧波第二中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "fklmstsslibrary" ? (
                <h3>鳳溪廖萬石堂中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "skhylmpslibrary" ? (
                <h3>聖公會仁立紀念小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "hkmlcpslibrary" ? (
                <h3>港澳信義會小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "kwpsktlibrary" ? (
                <h3>中華基督教會基華小學（九龍塘）</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "spccpslibrary" ? (
                <h3>聖保羅男女中學附屬小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "ktslibrary" ? (
                <h3>九龍工業學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "plkclscmclibrary" ? (
                <h3>保良局百周年李兆忠紀念中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "cpypslibrary" ? (
                <h3>香港學生輔助會小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "ccchwclibrary" ? (
                <h3>中華基督教會協和書院</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "lctmslibrary" ? (
                <h3>李志達紀念學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "ccckyclibrary" ? (
                <h3>中華基督教會基元中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "tkplibrary" ? (
                <h3>聖公會蔡功譜中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "kcckclibrary" ? (
                <h3>葵涌蘇浙公學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "tcpslibrary" ? (
                <h3>香港中文大學校友會聯會張煊昌學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "ylllibrary" ? (
                <h3>基督教香港信義會元朗信義中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "yottkppslibrary" ? (
                <h3>仁愛堂田家炳小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "pkclibrary" ? (
                <h3>培僑書院</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "apswlibrary" ? (
                <h3>黃埔宣道小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "wcbsslibrary" ? (
                <h3>香港教育工作者聯會黃楚標中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "htsstkolibrary" ? (
                <h3>將軍澳香島中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "fssaslibrary" ? (
                <h3>福建中學附屬學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "stspslibrary" ? (
                <h3>西貢崇真天主教學校（小學部）</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "csshklibrary" ? (
                <h3>香港潮商學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "lstykhllibrary" ? (
                <h3>樂善堂楊葛小琳中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "cccmyclibrary" ? (
                <h3>中華基督教會銘賢書院</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "plktkplibrary" ? (
                <h3>保良局田家炳小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "brplibrary" ? (
                <h3>天虹小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "ssslibrary" ? (
                <h3>創知中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "csalibrary" ? (
                <h3>棉紡會中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "wkslibrary" ? (
                <h3>大埔崇德黃建常紀念學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "clbpslibrary" ? (
                <h3>佛教志蓮小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "ccllibrary" ? (
                <h3>基督教宣道會徐澤林紀念小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "kmschlibrary" ? (
                <h3>循道學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "lkklpslibrary" ? (
                <h3>路德會梁鉅鏐小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "mwslibrary" ? (
                <h3>梅窩學校</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "xpypssclibrary" ? (
                <h3>香港普通話研習社科技創意小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "twghkydslibrary" ? (
                <h3>東華三院甲寅年總理中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "msschlibrary" ? (
                <h3>瑪利諾中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "skhktlibrary" ? (
                <h3>聖公會基德小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "tssslibrary" ? (
                <h3>德信中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "fk1pslibrary" ? (
                <h3>鳳溪第一小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "swcslibrary" ? (
                <h3>新會商會中學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "tswgpslibrary" ? (
                <h3>天水圍官立小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "skhhcwpslibrary" ? (
                <h3>聖公會何澤芸小學</h3>
              ) : (
                <></>
              )}
              {process.env.REACT_APP_WEB_NAME === "semopslibrary" ? (<h3>獻主會聖馬善樂小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ycpslibrary" ? (<h3>油蔴地天主教小學（海泓道）</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "flpslibrary" ? (<h3>粉嶺公立學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "plkshslibrary" ? (<h3>保良局蕭漢森小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "hytpslibrary" ? (<h3>順德聯誼總會何日東小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "shcclibrary" ? (<h3>嘉諾撒聖心書院</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "kcsslibrary" ? (<h3>加拿大神召會嘉智中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "cypylibrary" ? (<h3>潮陽百欣小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "frgpslibrary" ? (<h3>農圃道官立小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "cwfllslibrary" ? (<h3>基督教香港信義會信愛學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "hypslibrary" ? (<h3>協恩中學附屬小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ktpslibrary" ? (<h3>中華基督教會灣仔堂基道小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "lktlibrary" ? (<h3>佛教林金殿紀念小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "sppslibrary" ? (<h3>聖公會聖彼得小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "jccsskclibrary" ? (<h3>裘錦秋中學(葵涌)</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "hkugapslibrary" ? (<h3>港大同學會小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "wbsslibrary" ? (<h3>救世軍卜維廉中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "cccktpslibrary" ? (<h3>中華基督教會基全小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "heungtolibrary" ? (<h3>香島中學（九龍塘）</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "stfawmtpslibrary" ? (<h3>順德聯誼總會伍冕端小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "gtslibrary" ? (<h3>優才(楊殷有娣)書院中學部</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "gtplibrary" ? (<h3>優才(楊殷有娣)書院小學部</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "nwcpslibrary" ? (<h3>伍華小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "cpcydsslibrary" ? (<h3>金巴崙長老會耀道中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "kwpslibrary" ? (<h3>中華基督教會基華小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "taipocrgpslibrary" ? (<h3>新界婦孺福利會基督教銘恩小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ltylibrary" ? (<h3>世界龍岡學校劉德容紀念小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "spcpslibrary" ? (<h3>聖伯多祿天主教小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ylmasslibrary" ? (<h3>元朗商會中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "skhlsklibrary" ? (<h3>聖公會李兆強小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "dbslibrary" ? (<h3>拔萃男書院</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "blmcpslibrary" ? (<h3>浸信會呂明才小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "hyplibrary" ? (<h3>嗇色園主辦可譽中學暨可譽小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "copslibrary" ? (<h3>聖公會主愛小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ckpslibrary" ? (<h3>中華基督教會元朗真光小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ucckelibrary" ? (<h3>滙基書院 (東九龍)</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "tslibrary" ? (<h3>台山商會學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "wscsslibrary" ? (<h3>王肇枝中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ltfclibrary" ? (<h3>林大輝中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "khsslibrary" ? (<h3>中華基督教會基協中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "kwmwpslibrary" ? (<h3>中華基督教會基慧小學(馬灣)</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "stcpslibrary" ? (<h3>深井天主教小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "cjslibrary" ? (<h3>才俊學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "hwclibrary" ? (<h3>漢華中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ttlsklibrary" ? (<h3>聖公會德田李兆強小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "lkfmslibrary" ? (<h3>柏立基教育學院校友會盧光輝紀念學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "caclibrary" ? (<h3>宣道中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "tmmslibrary" ? (<h3>曾梅千禧學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "aspcpslibrary" ? (<h3>香港仔聖伯多祿天主教小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "nwcsslibrary" ? (<h3>天主教伍華中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "flsslibrary" ? (<h3>基督教香港信義會心誠中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "skhsjpslibrary" ? (<h3>聖公會聖雅各小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "scwpslibrary" ? (<h3>東華三院冼次雲小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "lkwlibrary" ? (<h3>順德聯誼總會梁潔華小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "twghfwftslibrary" ? (<h3>東華三院馮黃鳳亭中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "htmstswlibrary" ? (<h3>天水圍香島中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "csklsclibrary" ? (<h3>陳瑞祺（喇沙）書院</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "poslibrary" ? (<h3>香港基督教服務處培愛學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "plkcjylibrary" ? (<h3>保良局朱正賢小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ccstlibrary" ? (<h3>基督書院</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "plktkpmlibrary" ? (<h3>保良局田家炳千禧小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "wtsgpslibrary" ? (<h3>黃大仙官立小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "cdtilibrary" ? (<h3>商務坊</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "fkislibrary" ? (<h3>鳳溪創新小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "spclibrary" ? (<h3>聖保羅書院</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "lstwcmlibrary" ? (<h3>樂善堂王仲銘中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "cwsalibrary" ? (<h3>中西區聖安多尼學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "cpswtslibrary" ? (<h3>嘉諾撒小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "cfslibrary" ? (<h3>明愛粉嶺陳震夏中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "qcobasslibrary" ? (<h3>皇仁舊生會中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "cyflibrary" ? (<h3>中華基督教會全完第一小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ywgslibrary" ? (<h3>英華女學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "chsslibrary" ? (<h3>孔聖堂中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "tsaclibrary" ? (<h3>台山商會中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "hominglibrary" ? (<h3>嗇色園主辦可銘學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ccvclibrary" ? (<h3>明愛華德中書院</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ampslibrary" ? (<h3>亞斯理衛理小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "twghmkclibrary" ? (<h3>東華三院馬錦燦紀念小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "fhmslibrary" ? (<h3>炮台山循道衛理中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "sfcslibrary" ? (<h3>嘉諾撒聖方濟各學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "hmtgsslibrary" ? (<h3>何文田官立中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "jcgsslibrary" ? (<h3>賽馬會官立中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "wcblibrary" ? (<h3>香港教育工作者聯會黃楚標學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "plkno1whclibrary" ? (<h3>保良局第一張永慶中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "skhkypslibrary" ? (<h3>聖公會基恩小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "yanlaaplibrary" ? (<h3>聖公會仁立小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "bwcsslibrary" ? (<h3>香海正覺蓮社佛教黃藻森學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "skhkeihinlibrary" ? (<h3>聖公會基顯小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "hkmadavidlilibrary" ? (<h3>香港管理專業協會李國寶中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "lwlclibrary" ? (<h3>循道衞理聯合教會李惠利中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "yyshklibrary" ? (<h3>育賢學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "wcsylibrary" ? (<h3>港澳信義會黃陳淑英紀念學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "cypslibrary" ? (<h3>香港潮陽小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "scsglibrary" ? (<h3>聖傑靈女子中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "hnyplibrary" ? (<h3>九龍城浸信會禧年(恩平)小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "sepkslibrary" ? (<h3>官立嘉道理爵士小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "hpccpslibrary" ? (<h3>旅港開平商會學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "kcclibrary" ? (<h3>蘇浙公學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "tktcpshfrlibrary" ? (<h3>大角嘴天主教小學（海帆道）</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "srolslibrary" ? (<h3>聖羅撒學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "lthlibrary" ? (<h3>東華三院李東海小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "hkbuaslibrary" ? (<h3>香港浸會大學附屬學校王錦輝中小學(小學部)</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "lkpslibrary" ? (<h3>順德聯誼總會李金小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "hzitlibrary" ? (<h3>元朗朗屏邨惠州學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "skhcfcnlibrary" ? (<h3>聖公會置富始南小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "sjbcpslibrary" ? (<h3>聖若翰天主教小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "kbsjblibrary" ? (<h3>九龍灣聖若翰天主教小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "plkshsnlibrary" ? (<h3>保良局何壽南小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ssnahkwslibrary" ? (<h3>三水同鄉會禤景榮學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "wtsmclibrary" ? (<h3>香港四邑商工總會黃棣珊紀念中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "qpslibrary" ? (<h3>伊利沙伯中學舊生會小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "qbpslibrary" ? (<h3>伊利沙伯中學舊生會小學分校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "skhhspslibrary" ? (<h3>聖公會主風小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "yotpslibrary" ? (<h3>仁愛堂劉皇發夫人小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "sdbnsmlibrary" ? (<h3>天主教慈幼會伍少梅中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "tkfsclibrary" ? (<h3>東莞同鄉會方樹泉學校</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "bthclibrary" ? (<h3>佛教大雄中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "yingwapslibrary" ? (<h3>英華小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "lplsslibrary" ? (<h3>東莞工商總會劉百樂中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "cskmslibrary" ? (<h3>陳樹渠紀念中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "tkocpslibrary" ? (<h3>將軍澳天主教小學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "ych2sslibrary" ? (<h3>仁濟醫院第二中學</h3>) : (<></>)}
              {process.env.REACT_APP_WEB_NAME === "jvsjlibrary" ? (<h3>佐敦谷聖若瑟天主教小學</h3>) : (<></>)}
              */}

              {schoolObj ? <h3>{schoolObj.chiName}</h3> : <></>}

              {(process.env.REACT_APP_WEB_NAME === "sueplibrary" &&
                (process.env.REACT_APP_BACKEND ===
                  "https://suepsso.octopus-tech.com" ||
                  window.location.host.includes("hkpl"))) ||
                process.env.REACT_APP_CLIENT_ID === "hkpl" ? (
                <h3>
                  <Trans>hkpl</Trans>
                </h3>
              ) : (
                <></>
              )}

              {process.env.REACT_APP_WEB_NAME === "sueplibrary" &&
                process.env.REACT_APP_BACKEND === "https://lib-api.nblib.com" ? (
                <h3>DEMO</h3>
              ) : (
                <></>
              )}
            </div>

            <div className={style.InventoryRow}>
              <div>
                {props.borrowBookList.borrowBookList.find(
                  (it: any) => it.productId == params.id
                ) !== undefined ? (
                  <>
                    <div className={style.btnGroupDiv}>
                      <Button
                        styleType={"upperNavbarButton"}
                        loadingStyle={{ width: "20px", height: "20px" }}
                        isLoading={loadingProd}
                        callBack={() => handleReadBook("read")}
                      >
                        <Trans>Read</Trans>
                      </Button>
                    </div>
                  </>
                ) : productBorrowStatus?.canBorrowCopy! > 0 ? (
                  <>
                    <h5>
                      <Trans>BorrowRemainder</Trans>
                    </h5>
                    <h5 style={{ color: "grey" }}>
                      {productBorrowStatus?.canBorrowCopy!}
                    </h5>
                  </>
                ) : (
                  <h5>
                    {i18n.language === "cn"
                      ? converter("借閱已滿")
                      : "借閱已滿"}
                  </h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.toggleGroupContainer}>
        <div className={style.toggleGroup}>
          {/* <div className={style.toggleSilder}>123</div> */}
          <Button
            buttonType={"button"}
            styleType={
              toggleInfo === "info" ? "toggleButtonCurrent" : "toggleButton"
            }
            callBack={(value) => handletoggle("info")}
            // onClick={activeLogin()}
          >
            <Trans>BriefIntroduction</Trans>
          </Button>
          <Button
            buttonType={"button"}
            styleType={
              toggleInfo === "author" ? "toggleButtonCurrent" : "toggleButton"
            }
            callBack={(value) => handletoggle("author")}
          >
            <Trans>AuthorProfile</Trans>
          </Button>
          {/* <Button
            buttonType={"button"}
            styleType={
              toggleInfo === "comment" ? "toggleButtonCurrent" : "toggleButton"
            }
            callBack={(value) => handletoggle("comment")}
          >
            <Trans>Comment</Trans>
          </Button> */}
        </div>
        <div className={style.commentInnerContainer}>
          <div
            className={
              toggleInfo === "info"
                ? style.introParagraph
                : style.introParagraphNonActive
            }
          >
            {/* <p>{displayData.productDescription && displayData.productDescription}</p> */}
            <p>
              {i18n.language === "cn"
                ? displayData.productDescription &&
                converter(displayData.productDescription)
                : displayData.productDescription &&
                displayData.productDescription}
            </p>
          </div>
          <div
            className={
              toggleInfo === "author"
                ? style.introParagraph
                : style.introParagraphNonActive
            }
          >
            {/* <p>{displayData.aboutAuthor && displayData.aboutAuthor}</p> */}
            <p>
              {i18n.language === "cn"
                ? displayData.aboutAuthor && converter(displayData.aboutAuthor)
                : displayData.aboutAuthor && displayData.aboutAuthor}
            </p>
          </div>
          <div
            className={
              toggleInfo === "comment"
                ? style.bookComments
                : style.bookCommentsNonActive
            }
          >
            <div className={style.commentBoxInnerContainer}>
              <div className={style.commenterInfo}>
                <div className={style.commenter}>
                  {localStorage.getItem(PATH.USER_INFO) != null
                    ? JSON.parse(localStorage.getItem(PATH.USER_INFO)!!).name
                    : ""}
                  {/* {JSON.parse(localStorage.getItem(PATH.USER_INFO)!!).name} */}
                </div>
                <div className={style.rating}>
                  <Rating
                    modify={true}
                    value={rating}
                    callBack={(value: number) => setRating(value)}
                  />
                </div>
              </div>
              <div className={style.comment}>
                <div className={style.commentTextarea}>
                  <Textarea
                    placeholder={t("comment100")}
                    styleType={"commentInput"}
                    callBack={(value) => setMessage(value)}
                    value={message}
                  ></Textarea>
                </div>

                <div className={style.commentButton}>
                  <Button
                    styleType={"upperNavbarButton"}
                    callBack={() => handlePostComment()}
                  >
                    <Trans>Comment</Trans>
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className={style.horizontalLineContainer}> */}
            {commentDisplay.map((it: any, idx: number) => (
              <div className={style.commentContainer}>
                <div className={style.commenterInfo}>
                  <div className={style.commenter}>{it.memberUsername}</div>
                  <div className={style.rating}>
                    <Rating modify={false} rate={it.rating} />
                  </div>
                </div>
                <div className={style.comment}>
                  <div className={style.commentText}>{it.comment}</div>

                  <div
                    className={style.commentDate}
                    style={{ fontSize: "12px" }}
                  >
                    {dateToString(it.createAt, "DD/MM/yyyy")}
                  </div>
                </div>
              </div>
            ))}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className={pageStatusStart ? style.pageStatus : ""}
                onClick={() => handlePreviousPgae()}
              >
                {pageStatusStart ? <Trans>PreviousPage</Trans> : ""}
              </div>

              <div
                className={pageStatusEnd ? style.pageStatus : ""}
                onClick={() => handleNextPgae()}
              >
                {pageStatusEnd ? <Trans>NextPage</Trans> : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.relatedBookContainer}>
        <h4>
          <Trans>RelatedBook</Trans>
        </h4>
        <div className={style.relatedBookInnerContainer}>
          {displayData.relatedProducts &&
            displayData.relatedProducts.map((item: any, idx: number) => (
              <BookViewRelated
                props={item}
                key={idx}
                bookMarkList={props.bookMarkList}
              />
            ))}
        </div>
      </div>
      <DialogBox
        styleType={PATH.STYLETYPE_FOUR}
        title={<Trans>BorrowBook</Trans>}
        p1={<Trans>BorrowBookText</Trans>}
        bookName={displayData && displayData.productNameChi}
        p2={<Trans>QuestionMark</Trans>}
        showDialog={openBorrowDialog}
        children={
          <div className={style.dialogButtonContainer}>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => setOpenBorrowDialog(false)}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => borrowBookComfirm()}
            >
              <Trans>Borrow</Trans>
            </Button>
          </div>
        }
      />
      <DialogBox
        styleType={PATH.STYLETYPE_THIRD}
        title={<Trans>BookReservation</Trans>}
        p1={<Trans>BookReservationText</Trans>}
        bookName={displayData && displayData.productNameChi}
        p2={<Trans>QuestionMark</Trans>}
        showDialog={openReserveDialog}
        children={
          <div className={style.dialogButtonContainer}>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => setOpenReserveDialog(false)}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => setOpenEmailDialog(true)}
              //callBack={() => reserveBookComfirm(params.id!!)}
            >
              <Trans>Booking</Trans>
            </Button>
          </div>
        }
      />

      <DialogBox
        styleType={PATH.STYLETYPE_THIRD}
        title={<Trans>AppointmentNotice</Trans>}
        p1={<Trans>AppointmentNoticeText</Trans>}
        bookName={displayData && displayData.productNameChi}
        showDialog={openEmailDialog}
        children={
          <>
            <div className={style.pad}>
              <Input
                type="email"
                placeholder={t("inputEmail")}
                styleType={PATH.STYLETYPE_FIFTH}
                disabled={
                  props.userInfo && Object.keys(props.userInfo).length > 0
                    ? props.userInfo.member.email !== undefined &&
                      props.userInfo.member.email !== ""
                      ? true
                      : false
                    : false
                }
                // hidden={
                //   needEmail
                //     ? false
                //     : props.userInfo?.member.email !== undefined &&
                //       props.userInfo?.member.email !== ""
                //     ? false
                //     : true
                // }
                // hidden={needEmail ? true : props.userInfo?.member.email !== undefined && props.userInfo?.member.email !== "" ? true : false}
                value={bookingEmail}
                callBack={(value: any) => setBookingEmail(value)}
              />
            </div>
            <div className={style.dialogButtonContainer}>
              <Button
                styleType={"upperNavbarButton"}
                callBack={() => {
                  setBookingEmail("");
                  setOpenEmailDialog(false);
                }}
              >
                <Trans>Cancel</Trans>
              </Button>
              <Button
                styleType={"upperNavbarButton"}
                callBack={() => {
                  if (needEmail && !validateEmail(bookingEmail)) {
                    alert("請輸入有效電郵");
                  } else {
                    reserveBookComfirm(params.id!!);
                  }
                }}
              >
                <Trans>submit</Trans>
              </Button>
            </div>
          </>
        }
      />
      <DialogBox
        styleType={PATH.STYLETYPE_FOUR}
        title="無法借閱"
        p1="你的借閱書本已達上限，請先還書後再嘗試"
        showDialog={borrowBookMaxDialog}
        children={
          <div className={style.dialogButtonContainer}>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => console.log()}
              hidden
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => setBorrowBookMaxDialog(false)}
            >
              <Trans>好</Trans>
            </Button>
          </div>
        }
      />
      <DialogBox
        styleType={PATH.STYLETYPE_FOUR}
        title="無法預約"
        p1="你的預約書本已達上限，請先取消預約後再嘗試"
        showDialog={reserveBookMaxDialog}
        children={
          <div className={style.dialogButtonContainer}>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => console.log()}
              hidden
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => setReserveBookMaxDialog(false)}
            >
              <Trans>好</Trans>
            </Button>
          </div>
        }
      />
      <DialogBox
        styleType={PATH.STYLETYPE_FOUR}
        title="是否開啟書本?"
        showDialog={confirmOpenBookDialog}
        children={
          <div className={style.dialogButtonContainer}>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => {
                setConfirmOpenBookDialog(false);
                localStorage.setItem("openBookUrl", "");
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              styleType={"upperNavbarButton"}
              callBack={() => {
                setConfirmOpenBookDialog(false);
                if (
                  localStorage.getItem("openBookUrl") !== undefined &&
                  localStorage.getItem("openBookUrl") !== null &&
                  localStorage.getItem("openBookUrl") !== ""
                ) {
                  window.open(localStorage.getItem("openBookUrl")!, "_blank");
                }
              }}
            >
              {/* <Trans>好</Trans> */}
              確定
            </Button>
          </div>
        }
      />

      <DialogBox
        styleType={PATH.STYLETYPE_FOUR}
        showDialog={showConfirmDialog}
        p1={confirmDialogMessage}
        title={<Trans>SystemMessage</Trans>}
        scroll={false}
      >
        <div className={style.dialogButtonContainer2}>
          <Button
            styleType={"submitButton"}
            callBack={() => {
              localStorage.setItem(
                PATH.LOGIN_REDIRECT,
                window.location.pathname
              );
              // console.log("window.location.pathname", window.location.pathname)
              setShowConfirmDialog(false);
              if (confirmDialogMessage === "請先登入") {
                if (
                  process.env.REACT_APP_BACKEND ==
                  "https://suepsso.octopus-tech.com" ||
                  window.location.host.includes("hkpl") ||
                  process.env.REACT_APP_CLIENT_ID === "hkpl" ||
                  process.env.REACT_APP_CLIENT_ID === "uatlib"
                ) {
                  window.parent.parent.location = PATH.HKPL_SSO_PATH;
                } else {
                  navigate(PATH.LOGIN_PATH);
                }
              }
            }}
          >
            <Trans>Confirm</Trans>
          </Button>
        </div>
      </DialogBox>
    </>
  );
}

const mapStateToProps = (state: any) => {
  //console.log("taesdfads", state);
  return {
    borrowBookList: state.myBorrowBookList,
    reserveBookList: state.myReserveBookList,
    auth: state.login.auth,
    bookMarkList: state.myBookMarkList,
    userInfo: state.userInfo.userInfo,
    reader: state.reader,
  };
};
const actionCreator = {
  myBorrowBookList,
  handleLogin,
  myReserveBookList,
  myBookMarkList,
  handleUserInfo,
  handleReaderMode,
};
export default connect(mapStateToProps, actionCreator)(BookDetail);
