import React, { useEffect, useRef } from 'react';
import style from "./PageContent.module.css";
import { useNavigate } from 'react-router-dom';

interface Props {
  htmlContent: string;
}

function PageContentHTMLContent({ htmlContent }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  function collectionHas(a: string | any[] | NodeListOf<any>, b: any) { //helper function (see below)
    for(var i = 0, len = a.length; i < len; i ++) {
        if(a[i] == b) return true;
    }
    return false;
  }
  function findParentBySelector(elm: any, selector: any) {
    var all = document.querySelectorAll(selector);
    var cur = elm.parentNode;
    while(cur && !collectionHas(all, cur)) { //keep going up until you find a match
        cur = cur.parentNode; //go up
    }
    return cur; //will return null if not found
  }

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const handleClick = (event: MouseEvent) => {
        event.preventDefault();
        //console.log('CP1', event.target);
        let _link = "";
        if (event.target instanceof HTMLAnchorElement) {
          const target = event.target as HTMLAnchorElement;
          const link = target.href;
          _link = link;
        } else {
          const target = event.target;
          const yourElm = target;
          const selector = "A";
          const parentA = findParentBySelector(yourElm, selector);
          if (parentA == null) {
          } else {
            const targetA = parentA as HTMLAnchorElement;
            const linkA = targetA.href;
            _link = linkA;
          }
        }

        // Add your custom logic here
        //_link = _link.replace('http://','https://');
        if (_link && _link.includes(process.env.REACT_APP_REDIRECT_URI!) ) {
          let _url = _link.replace(process.env.REACT_APP_REDIRECT_URI!, "");
          navigate(_url);
        } else {
          // Web
          window.open(_link, "_blank");

          // App 
          // TODO
        }
      };

      const anchorTags = container.getElementsByTagName('a');
      for (let i = 0; i < anchorTags.length; i++) {
        anchorTags[i].addEventListener('click', handleClick);
      }

      return () => {
        for (let i = 0; i < anchorTags.length; i++) {
          anchorTags[i].removeEventListener('click', handleClick);
        }
      };
    }
  }, []);

  return (
    <div className={style.exContainer} ref={containerRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
}

export default PageContentHTMLContent;