import { useEffect, useState } from "react";
import style from "./Search.module.css";
import { Link, useNavigate } from "react-router-dom";
import arrowBack from "../../assets/svg/back.svg";
import loadingGif from "../../assets/svg/loading.gif";
import Button from "../../components/Button/Button";
import DialogBox from "../../components/DialogBox/DialogBox";
import Input from "../../components/Input/Input";
import SelectOption from "../../components/SelectOption/SelectOption";
import * as PATH from "../../utils/Contants";
import {
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_DATALIST,
  STYLETYPE_SEARCH_INPUT,
  STYLETYPE_SEARCH_INPUT_OPTION,
} from "../../utils/Contants"; //"../../global/Contants";
import useWindowSize from "../../utils/useWindowSize";
import { Trans, useTranslation } from "react-i18next";
import api from "../../api/api";
import { genApiConfig } from "../../utils/fetch-caller";
import { DashboardControllerApi } from "../../fetcher";
import SelectOrgSearchFilterById from "../../components/SearchFilter/SelectOrgSearchFilterById";

export default function Search() {
  const { width, height } = useWindowSize();
  const { t, i18n } = useTranslation();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [years, setYears] = useState<any>([]);
  const [inputOption, setInputOption] = useState("all");
  const [fromYear, setFromYear] = useState<any>([]);
  const [toYear, setToYear] = useState<any>([]);
  const [year, setYear] = useState<any>([]);
  const [publisher, setPublisher] = useState<any>([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [organization, setOrganization] = useState<any>([]);
  const [publishHouse, setPublishHouse] = useState([]);
  const [categories, setCategories] = useState<any>([]);
  const [categoryArray, setCategoryArray] = useState<any>([]);
  const [checkedState, setCheckedState] = useState<any>([]);
  const [searchData, setSearchData] = useState<any>([]);
  const [pathName, setPathName] = useState<any>([
    PATH.SEARCH_RESULT_PATH + "?",
  ]);

  const navigate = useNavigate();

  const handleNavigate = () => {
    //navigate(ToParams());
    document.getElementById("submit_btn")?.click();
  };

  const OpenCC = require("opencc-js");
  const converter = OpenCC.Converter({ from: "hk", to: "cn" });

  useEffect(() => {
    api.getAllOrganization().then((data) => {
      setOrganization(data)
    });
    new DashboardControllerApi(genApiConfig())
      .getDashboardSortUsingGET()
      //.getDashboardCategoryUsingGET()
      .then((data: any) => {
        let result = data.map((it: Object) => {
          return Object.assign(it, { isChecked: false });
        });
        setCategories(result);
      });
    const handleKeyup = (e: any) => {
      console.log(e);
      if (e.keyCode === 13) {
        console.log("navigate");
        handleNavigate();
      }
    };

    document.getElementById("keyword")?.addEventListener("keyup", handleKeyup);
    return () =>
      document
        .getElementById("keyword")
        ?.removeEventListener("keyup", handleKeyup);
  }, []);
  useEffect(() => {
    var now = new Date();
    let arr = [];
    let publishHouse = 
      [].concat.apply(
        [],
        organization.map((org: any) => {
          //return org.subOrganization
          if (
            org.subOrganization.length > 0
          ) {
            return org.subOrganization
          } else {
            if (org.name == "Helbling Languages") {
              return org
            } else {
              return org.subOrganization
            }
          }
        })
      )
    ;
    setPublishHouse(
      publishHouse
    );
    for (var i = 1900; i <= now.getFullYear(); i++) {
      arr.push({ id: i, name: i.toString() });
    }
    setYears(arr.reverse());
  }, [organization, categories]);

  // console.log("from year", fromYear);
  // console.log("to year", toYear);
  // console.log("publisher", publisher);
  // console.log("inputOption", inputOption);
  // console.log("keyword", keyword);

  let ToParams: any = () => {
    let p: any[] = [];
    //console.log("inputOption",inputOption)
    if (inputOption !== "") {
      p.push("inputOption" + "=" + inputOption);
    }
    if (keyword !== "") {
      p.push("keyword=" + keyword);
    }
    if (fromYear && fromYear.id) {
      p.push("fromYear=" + fromYear.id);
    }
    if (toYear && toYear.id) {
      p.push("toYear=" + toYear.id);
    }
    if (publisher && publisher.organizationId) {
      p.push("organizationId=" + publisher.organizationId);
    }
    const result = categories
      .filter((it: any) => it.isChecked)
      .map((it: any) => it.categoryId);
    if (result.length > 0) p.push("categoryId=" + result);

    if (p.length < 1) {
      return alert(`請輸入關鍵字 或年份 或出版商`);
    }

    return PATH.SEARCH_RESULT_PATH + "?" + p.join("&");
  };

  const handleOnChange = (position: any) => {
    let newArr = categories.map((it: any) => {
      if (it.categoryId === position) {
        it.isChecked = !it.isChecked;
      } else {
        it.isChecked = it.isChecked;
      }
      return it;
    });

    setCategories(newArr);
  };

  return (
    <>
      <div className={style.Header}>
        <div className={style.backHomeButtonContainer}>
          <div>
            <Button
              styleType={"upperNavbarButton"}
              callBack={(value) => navigate(-1)}
            >
              <img src={arrowBack} />
              <Trans>PreviousPage</Trans>
            </Button>
          </div>
        </div>
      </div>
      <div className={style.pageContentLayout}>
        <div></div>
        <div>
          <div className={style.title}>
            {width <= 768 ? (
              <h2>
                <Trans>AdvancedSearch</Trans>
              </h2>
            ) : (
              <h1>
                <Trans>AdvancedSearch</Trans>
              </h1>
            )}
          </div>
          <div
            className={
              width < 768 ? style.selectOptionsRow2 : style.selectOptionsRow
            }
          >
            <div className={style.InputKeyword}>
              <SelectOption
                placeholder={t("InputKeyword")}
                value={keyword}
                styleType={STYLETYPE_SEARCH_INPUT}
                callBack={(value) => setKeyword(value)}
              />
            </div>
            <div className={style.inputOption}>
              <SelectOption
                placeholder="InputOption"
                firstValue="pleasechoose"
                styleType={STYLETYPE_SEARCH_INPUT_OPTION}
                callBack={(value) => setInputOption(value)}
              />
            </div>
          </div>

          <div
            className={
              width < 768 ? style.selectOptionsRow2 : style.selectOptionsRow
            }
          >
            <div className={style.selectMargin}>
              <SelectOption
                placeholder="PublishingYearFrom"
                firstValue="pleasechoose"
                items={years}
                styleType={STYLETYPE_SECOND}
                callBack={(value) => setFromYear(value)}
              />
            </div>
            <div className={style.selectMargin}>
              <SelectOption
                placeholder="PublishingYearTo"
                firstValue="pleasechoose"
                items={years}
                styleType={STYLETYPE_SECOND}
                callBack={(value) => setToYear(value)}
              />
            </div>
            {/* <div className={style.selectMargin}>
              <SelectOption
                placeholder="Publisher"
                firstValue="pleasechoose"
                items={publishHouse}
                styleType={STYLETYPE_SECOND}
                callBack={(value) => setPublisher(value)}
              />
            </div> */}

            <div className={style.selectMarginLong}>
              <SelectOption
                placeholder={t("SearchPublisherKeyword")}
                firstValue="pleasechoose"
                items={publishHouse}
                styleType={STYLETYPE_DATALIST}
                callBack={(value) => setPublisher(value)}
              />
            </div>

            {/* <div style={{ marginTop: "10px" }}>
              <SelectOrgSearchFilterById
                data={publishHouse}
                multiple={false}
                expand={false}
                canDelete={false}
                canEdit={false}
                callBack={(value: any) => setPublisher(value)}
              />
            </div> */}
          </div>
          <div style={{ marginTop: "50px", fontWeight: "bold" }}>
            <Trans>Bookcategory</Trans>
          </div>
          <div className={style.ContentItem_container}>
            {categories.map((it: any, index: number) => (
              <div className={style.ContentItemlist} key={index}>
                {/* <Input
                  type="checkbox"
                  styleType="checkbox"
                  callBack={(value) => ItemCheckedChange(it)}
                />
                <p className={style.margin_7px}>{it.categoryName}</p> */}
                <input
                  type="checkbox"
                  id={`custom-checkbox-${index}`}
                  name={it.categoryName}
                  value={it.categoryName}
                  checked={it.isChecked}
                  onChange={(event) => {
                    handleOnChange(it.categoryId);
                  }}
                />
                <label
                  style={{ fontSize: "14px", marginLeft: "6px" }}
                  htmlFor={`custom-checkbox-${index}`}
                >
                  {i18n.language === "cn"
                    ? converter(it.categoryName)
                    : it.categoryName}
                </label>
              </div>
            ))}
          </div>

          <div className={style.displayFlex}>
            <Button
              id={"submit_btn"}
              styleType={STYLETYPE_MAIN}
              callBack={() => navigate(ToParams())}
              disabled={isSubmit}
            >
              {isSubmit ? "" : <Trans>Search</Trans>}
              <img
                src={loadingGif}
                style={{
                  margin: "0 auto",
                  width: "30px",
                  height: "30px",
                  display: isSubmit ? "block" : "none",
                }}
              />
            </Button>
          </div>
        </div>
        <div></div>
      </div>
      <DialogBox
        styleType={STYLETYPE_MAIN}
        showDialog={showDeleteDialog}
        title={"刪除書本資料"}
      ></DialogBox>
    </>
  );
}
